/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-08-29",
    versionChannel: "nightly",
    buildDate: "2023-08-29T00:21:45.895Z",
    commitHash: "0ed447b42877b10fdaaf913015eb2783cd9afb04",
};
